import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrophy, faCheckCircle, faFileAlt} from '@fortawesome/free-solid-svg-icons'
import {Col, Row} from 'reactstrap';
import CampaignFlowXs from '../../../assets/images/spardaimpuelsle2025-icons-startgrafik-mobil-rgb.svg';
import CampaignFlowLg from '../../../assets/images/spardaimpuelsle2025-icons-startgrafik-desktop-rgb.svg';
import moment from 'moment';

const campaignFlow = (props) => {

    const shortenDate = (from, until) => {
        if (until) {
            return moment(from).format('DD.MM.') + ' - ' + moment(until).format('DD.MM.');
        }
        return moment(from).format('DD.MM.');
    };

    return (
        <picture>
            <source srcSet={CampaignFlowLg} media="(min-width: 768px)"/>
            <img src={CampaignFlowXs}
                 alt="SpardaImülsle-Landkreis-Challenge: Doppelte Förderung in den stärksten drei Landkreisen"
                 style={{width: '100%', height: "auto"}}/>
        </picture>
    )
};

export default campaignFlow;

